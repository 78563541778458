// src/App.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { CssBaseline, Box, Toolbar, AppBar, Typography } from '@mui/material';
import Sidebar from './components/Sidebar';
import ChatbotSidebar from './components/ChatbotSidebar';
import ExperimentDesign from './pages/ExperimentDesign';
import ParameterSpaceExploration from './pages/ParameterSpaceExploration';
import DataAnalysis from './pages/DataAnalysis';
import MachineModelingOptimization from './pages/MachineModelingOptimization';
import Home from './pages/Home';
import Login from './components/Login'; // Import Login component
import { theme } from './styles/theme';
import { ExperimentProvider } from './contexts/ExperimentContext';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Check if authenticated cookie exists
    if (Cookies.get('authenticated')) {
      setIsAuthenticated(true);
      setLoading(false)
    }else{
      setLoading(false)
    }

  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);

  };

  if (!isAuthenticated && !loading) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <ExperimentProvider>
      <Router>
        <CssBaseline />
        <Box sx={{ display: 'flex', background: theme.palette.grey[200], height: "100vh" }}>
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{ justifyContent: 'left'}}>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="h4" noWrap component="div" style={{fontSize: "30px"}}>
                  BioMethod AI
                </Typography>
              </Link>
            </Toolbar>
          </AppBar>

          <Sidebar />

          {/* Main content area */}
          <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8, mr: '380px' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/experiment-design" element={<ExperimentDesign />} />
              <Route path="/parameter-space-exploration" element={<ParameterSpaceExploration />} />
              <Route path="/data-analysis" element={<DataAnalysis />} />
              <Route path="/machine-modeling-optimization" element={<MachineModelingOptimization />} />
            </Routes>
          </Box>

          {/* Chatbot sidebar */}
          <ChatbotSidebar />
        </Box>
      </Router>
    </ExperimentProvider>
  );
}

export default App;
