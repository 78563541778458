import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface RFECVChartProps {
    cvScores: number[];
}

const RFECVChart: React.FC<RFECVChartProps> = ({ cvScores }) => {
    const data = {
        labels: cvScores.map((_, index) => index + 1),
        datasets: [
            {
                label: 'Cross-Validated R^2 Score',
                data: cvScores,
                fill: false,
                borderColor: 'rgba(75,192,192,1)',
                tension: 0.1,
            },
        ],
    };

    const options: ChartOptions<"line"> = {
        responsive: true,
        plugins: {
            legend: { position: 'top' as const },
            title: {
                display: true,
                text: 'RFECV: Optimal Number of Features Selection',
            },
        },
        scales: {
            x: { title: { display: true, text: 'Number of Features Selected' } },
            y: { title: { display: true, text: 'Cross-Validated R^2 Score' } },
        },
    };

    return <Line data={data} options={options} />;
};

export default RFECVChart;
