import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Divider,
    Tabs,
    Tab,
    Checkbox,
} from '@mui/material';
import TitleBar from '../components/Titlebar';
import ContentContainer from '../components/ContentContainer';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { theme } from '../styles/theme';
import FileUpload from '../components/FileUpload';
import ParameterRangeGraphs from '../components/ParameterRangeGraphs'
import UMAPExplorer from '../components/UMAPExplorer';
import ParameterRangeGraphsBar from '../components/ParameterRangeGraphsBar';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ParallelCoordinatesPlot from '../components/ParallelCoordinatesPlot';
import EucDistanceRecs from '../components/EucDistanceRecs';
import { calculateEuclideanDistance, generateFullFactorial, getHighLowValues, normalizeData } from '../helperfunctions';
import { saveAs } from 'file-saver';
import DatasetUploader from '../components/DatasetUploader';



const ParameterSpaceExploration = () => {
    const [data, setData] = useState([]);
    const [numericColumns, setNumericColumns] = useState<string[]>([]);
    const [selectedColumns, setSelectedColumns] = useState([]); // Start with all selected
    const [originalColumns, setOriginalColumns] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [recommendedPoints, setRecommendedPoints] = useState([]);

    const [showFileUpload, setShowFileUpload] = useState(true); // State to control the collapse

    const [selectedPoints, setSelectedPoints] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    useEffect(() => {
        if (data.length > 0) {
            setOriginalColumns(Object.keys(data[0])); // Capture the original column order
        }
    }, [data]);

    // Handle selecting/deselecting columns
    const toggleColumnSelection = (column) => {
        setSelectedColumns((prevSelected) =>
            prevSelected.includes(column)
                ? prevSelected.filter((col) => col !== column) // Deselect
                : [...prevSelected, column] // Select
        );
    };

    const [errorMessage, setErrorMessage] = useState('');
    const [showData, setShowData] = useState(false);
    const [fileName, setFileName] = useState<string | null>(null); // State for storing file name

    // Handle file upload
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
        setFileName(file.name); // Set the file name in the state

        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (fileExtension === 'csv' || fileExtension === 'tsv') {
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                delimiter: fileExtension === 'tsv' ? '\t' : ',',
                complete: (results: any) => processFileData(results.data),
                error: (error) => console.error("Error parsing file:", error),
            });
        } else if (fileExtension === 'xlsx') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result;
                if (result) {
                    const data = new Uint8Array(result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                    processFileData(jsonData);
                } else {
                    console.error("File read result is null.");
                }
            };
            reader.onerror = () => console.error("Error reading file.");
            reader.readAsArrayBuffer(file);
        } else {
            setErrorMessage('Unsupported file format. Please upload a CSV, TSV, or XLSX file.');
        }
    };

    // Process data to identify numeric columns
    const processFileData = (data: any) => {
        setData(data);
        const sampleRow = data[0];
        const numericCols = Object.keys(sampleRow).filter((col) => typeof sampleRow[col] === 'number');
        setNumericColumns(numericCols);
        setSelectedColumns(numericCols);
        setErrorMessage('');
        setShowData(false);
        setShowFileUpload(false)
    };
    // Sort selected columns to the beginning
    const sortedColumns = [...numericColumns].sort((a, b) => {
        const aSelected = selectedColumns.includes(a);
        const bSelected = selectedColumns.includes(b);
        return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });

    function TabContent({ children, value, index }) {
        return value === index && (
            <Box p={3}>
                {children}
            </Box>
        );
    }

    const generateRecommendedPoints = useCallback(() => {
        if (data.length === 0 || selectedColumns.length === 0) return;

        const highLow = getHighLowValues(data, selectedColumns);
        const fullFactorial = generateFullFactorial(highLow);
        const normalizedData = normalizeData(data, highLow, selectedColumns);

        const distances = fullFactorial.map((point) => {
            const normalizedPoint = normalizeData([point], highLow, selectedColumns)[0];
            const minDistance = Math.min(...normalizedData.map(row => calculateEuclideanDistance(normalizedPoint, row)));
            return { point, distance: minDistance };
        });

        const sortedPoints = distances.sort((a, b) => b.distance - a.distance);
        const maxDistance = sortedPoints[0]?.distance || 1;
        const topPoints = sortedPoints.map(d => ({
            ...d.point,
            normalizedDistance: d.distance / maxDistance || 0,
        }));

        setRecommendedPoints(topPoints);
        setSelectedPoints(topPoints.map((_, index) => index < 10));
    }, [data, selectedColumns]);

    useEffect(() => {
        generateRecommendedPoints();
    }, [data, selectedColumns, generateRecommendedPoints]);

    const handleDownload = () => {
        const selectedData = recommendedPoints.filter((_, i) => selectedPoints[i]);
        const csvContent = [
            Object.keys(selectedData[0]).join(','),
            ...selectedData.map(row => Object.values(row).join(','))
        ].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        saveAs(blob, 'recommended_points.csv');
    };

    const [includeRecommended, setIncludeRecommended] = useState(false);

    const getCombinedData = useCallback(() => {
        const selectedRecommendedPoints = recommendedPoints.filter((_, i) => selectedPoints[i]);
        return includeRecommended ? [...data, ...selectedRecommendedPoints] : data;
    }, [data, recommendedPoints, selectedPoints, includeRecommended]);

    const handleIncludeRecommendedChange = (event) => {
        setIncludeRecommended(event.target.checked);
    };

    const dataForPlot = includeRecommended ? getCombinedData() : data;


    return (
        <Box sx={{ padding: '0px', background: theme.palette.background.paper }}>
            <TitleBar title="Parameter Space Exploration" pageDescription="Upload a dataset and explore parameter spaces" />
            <ContentContainer>
                <DatasetUploader
                    fileName={fileName}
                    setFileName={setFileName}
                    handleFileUpload={handleFileUpload}
                    errorMessage={errorMessage}
                    data={data}
                    numericColumns={numericColumns}
                    selectedColumns={selectedColumns}
                    toggleColumnSelection={toggleColumnSelection}
                    showFileUpload={showFileUpload}
                    setShowFileUpload={setShowFileUpload}
                    selectColumnsText={"Select Variables To Explore"}
                />


                <Divider />

                {data.length > 0 && numericColumns.length > 0 &&


                    <Box display="flex" justifyContent="right" alignItems="center" mt={2} mb={2}>
                        <Checkbox
                            checked={includeRecommended}
                            onChange={handleIncludeRecommendedChange}
                        />
                        <Typography>Include Recommended Points in Data Plots</Typography>
                    </Box>

                }

                {data.length > 0 && numericColumns.length > 0 &&
                    <Box>
                        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label="View Selected Data" />
                            <Tab label="Recommended Combinations" />
                            <Tab label="Individual Parameter Ranges" />
                            <Tab label="Parameter Pair Plots" />
                            <Tab label="Parallel Coordinates Plot" />
                        </Tabs>

                        {/* View Selected Data */}
                        <TabContent value={tabValue} index={0}>


                            <TableContainer component={Paper} sx={{ maxHeight: 600, overflowY: 'auto' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {originalColumns.filter(column => selectedColumns.includes(column)).map(column => (
                                                <TableCell key={column}>{column}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row, index) => (
                                            <TableRow key={index}>
                                                {originalColumns.filter(column => selectedColumns.includes(column)).map(column => (
                                                    <TableCell key={column}>{row[column]}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </TabContent>
                        <TabContent value={tabValue} index={1}>
                            <TableContainer component={Paper} sx={{ maxHeight: 600, overflowY: 'auto' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {selectedColumns.map((col) => (
                                                <TableCell key={col}>{col}</TableCell>
                                            ))}
                                            <TableCell>Normalized Euclidean Distance</TableCell>
                                            <TableCell>Select</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recommendedPoints.map((point, index) => (
                                            <TableRow key={index}>
                                                {selectedColumns.map((col) => (
                                                    <TableCell key={col}>{point[col]}</TableCell>
                                                ))}
                                                <TableCell>{point.normalizedDistance.toFixed(4)}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedPoints[index] || false}
                                                        onChange={() => setSelectedPoints(prev => {
                                                            const updated = [...prev];
                                                            updated[index] = !updated[index];
                                                            return updated;
                                                        })}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box mt={4} mb={4}>
                                <Button variant="outlined" color="primary" onClick={handleDownload}>Download Selected Points</Button>
                            </Box>
                        </TabContent>
                        {/* Individual Parameter Ranges */}
                        <TabContent value={tabValue} index={2}>
                            <ParameterRangeGraphsBar data={dataForPlot} selectedColumns={selectedColumns} />
                        </TabContent>

                        {/* Parameter Pair Plots */}
                        <TabContent value={tabValue} index={3}>
                            <ParameterRangeGraphs data={dataForPlot} selectedColumns={selectedColumns} />
                        </TabContent>

                        {/* Parallel Coordinates Plot */}
                        <TabContent value={tabValue} index={4}>

                            <ParallelCoordinatesPlot data={dataForPlot} selectedColumns={selectedColumns} />
                        </TabContent>
                    </Box>
                }

            </ContentContainer>
        </Box>
    );
};

export default ParameterSpaceExploration;
