import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { linearRegression, rSquared } from 'simple-statistics';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RegressionAnalysis = ({ data, independentVars, dependentVar }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const chartsPerPage = 3;
    const totalPages = Math.ceil(independentVars.length / chartsPerPage);

    // Calculate correlation coefficient, regression line, and R²
    const calculateRegressionMetrics = (xVar) => {
        const xValues = data.map((row) => row[xVar]);
        const yValues = data.map((row) => row[dependentVar]);

        // Calculate regression line parameters (slope and intercept)
        const linearFit = linearRegression(xValues.map((x, i) => [x, yValues[i]]));

        // Create a function for the regression line
        const regressionLineFn = (x) => linearFit.m * x + linearFit.b;
        const regressionLine = xValues.map((x) => ({
            x,
            y: regressionLineFn(x),
        }));

        // Calculate correlation coefficient and R²
        const correlationCoefficient = Math.sqrt(
            rSquared(xValues.map((x, i) => [x, yValues[i]]), regressionLineFn)
        );
        const rSquare = rSquared(xValues.map((x, i) => [x, yValues[i]]), regressionLineFn);

        return { regressionLine, correlationCoefficient, rSquare };
    };

    // Helper function to get scatter data with regression line
    const getScatterData = (xVar) => {
        const xValues = data.map((row) => row[xVar]);
        const yValues = data.map((row) => row[dependentVar]);
        const { regressionLine, correlationCoefficient, rSquare } = calculateRegressionMetrics(xVar);

        return {
            labels: data.map((_, idx) => `Point ${idx + 1}`),
            datasets: [
                {
                    label: `${xVar} vs ${dependentVar}`,
                    data: xValues.map((x, idx) => ({ x, y: yValues[idx] })),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                },
                {
                    label: 'Regression Line',
                    data: regressionLine,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 2,
                    showLine: true, // Ensures line is drawn in scatter chart
                    fill: false,
                    pointRadius: 0,
                },
            ],
            metrics: {
                correlationCoefficient: correlationCoefficient.toFixed(4),
                rSquare: rSquare.toFixed(4),
            },
        };
    };

    const handlePrevious = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        setCurrentPage(0);
    }, [independentVars]);

    return (
        <Box sx={{ width: '100%', padding: '0px' }} mt={2}>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                {independentVars
                    .slice(currentPage * chartsPerPage, (currentPage + 1) * chartsPerPage)
                    .map((xVar, idx) => {
                        const scatterData = getScatterData(xVar);
                        return (
                            <Paper
                                key={xVar}
                                sx={{
                                    minWidth: '280px',
                                    maxWidth: '400px',
                                    width: '100%',
                                    height: '450px',
                                    padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant="subtitle1" align="center" gutterBottom>
                                    {`${xVar} vs ${dependentVar}`}
                                </Typography>
                                <Box sx={{ height: '300px' }}>
                                    <Scatter
                                        data={scatterData}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: { display: false },
                                            },
                                            scales: {
                                                x: {
                                                    title: { display: true, text: xVar },
                                                },
                                                y: {
                                                    title: { display: true, text: dependentVar },
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                                <Typography align="center" variant="body2">
                                    Correlation Coefficient: {scatterData.metrics.correlationCoefficient}
                                </Typography>
                                <Typography align="center" variant="body2">
                                    R²: {scatterData.metrics.rSquare}
                                </Typography>
                            </Paper>
                        );
                    })}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                    variant="contained"
                    onClick={handlePrevious}
                    disabled={currentPage === 0}
                    sx={{ mr: 1 }}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={currentPage >= totalPages - 1}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default RegressionAnalysis;
