import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemButton, Toolbar, Typography, Divider, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ScienceIcon from '@mui/icons-material/Science';
import ExploreIcon from '@mui/icons-material/Explore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { theme } from '../styles/theme';

const drawerWidth = 240;

function Sidebar() {
  const location = useLocation();

  const navItems = [
    { text: 'Experiment Design', icon: <ScienceIcon />, path: '/experiment-design' },
    { text: 'Parameter Space Exploration', icon: <ExploreIcon />, path: '/parameter-space-exploration' },
    { text: 'Data Analysis', icon: <AssessmentIcon />, path: '/data-analysis' },
    { text: 'ML Modeling and Optimization', icon: <ModelTrainingIcon />, path: '/machine-modeling-optimization' },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { 
          width: drawerWidth, 
          boxSizing: 'border-box', 
          backgroundColor: theme.palette.grey[900], 
          color: '#ffffff',
        },
      }}
    >
      {/* <Toolbar>
        <Typography variant="h6" sx={{ color: '#ffffff', margin: 'auto' }}>
          Dashboard
        </Typography>
      </Toolbar>
      <Divider /> */}
      <Box mt={10}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                color: location.pathname === item.path ? '#90caf9' : '#ffffff',
                backgroundColor: location.pathname === item.path ? '#333333' : 'inherit',
                '&:hover': {
                  backgroundColor: '#333333',
                },
              }}
            >
              <Box sx={{ color: location.pathname === item.path ? '#90caf9' : '#ffffff', mr: 2 }}>
                {item.icon}
              </Box>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
