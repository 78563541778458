import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Divider,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Chip,
    Tooltip,
    Collapse,
    Grid,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    TableBody,
    TableCell,
    Paper,
    Checkbox,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentContainer from '../components/ContentContainer';
import TitleBar from '../components/Titlebar';
import { theme } from '../styles/theme';
import { shuffle } from 'lodash';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { CSVLink } from 'react-csv';
import { useExperiment } from '../contexts/ExperimentContext';


interface RunData {
    [key: string]: number | string; // Define factor values as numbers or strings
}


const ExperimentDesign = () => {
    // State for factors and DOE type
    const { factors, setFactors } = useExperiment();

    const [factorName, setFactorName] = useState('');
    const [doeType, setDoeType] = useState('');
    const [factorRanges, setFactorRanges] = useState<{ [factor: string]: { low: number; high: number } }>({});
    const [experimentRuns, setExperimentRuns] = useState([]);
    const [showDOE, setShowDOE] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Handle DOE type selection
    const handleDoeTypeChange = (value: string) => {
        setDoeType(value);
    };
    const [randomize, setRandomize] = useState(false);
    const [runs, setRuns] = useState(experimentRuns);

    useEffect(() => {
        setFactors([])
    },[])

    // Toggle randomization and shuffle runs if enabled
    const handleRandomizeChange = () => {
        setRandomize(!randomize);
        setRuns(!randomize ? shuffle([...experimentRuns]) : experimentRuns);
    };

    // Tooltips for each DOE design type
    const doeTooltips: Record<string, string> = {
        "Plackett-Burman Design (PBD)": "Efficient screening design for identifying significant factors with minimal runs.",
        "Fractional Factorial Design": "A reduced factorial design that estimates main effects and low-level interactions.",
        "Full Factorial Design (2-Level)": "Explores all combinations of factors at two levels for a comprehensive analysis.",
        "Box-Behnken Design (BBD)": "A response surface methodology design without extreme points, useful for quadratic modeling.",
        "Central Composite Design (CCD)": "Used in response surface methodology, includes center and axial points for modeling curvature.",
    };

    const doeOptions = [
        "Plackett-Burman Design (PBD)",
        // "Fractional Factorial Design",
        "Full Factorial Design (2-Level)",
        "Box-Behnken Design (BBD)",
        "Central Composite Design (CCD)",
    ];

    // Handle adding a factor
    const addFactor = () => {
        if (factorName && !factors.includes(factorName)) {
            setFactors([...factors, factorName]);
            setFactorRanges((prevRanges) => ({
                ...prevRanges,
                [factorName]: { low: 0, high: 1 }  // Default values for new factor
            }));
            setFactorName(''); // Clear the input
        }
    };

    // Handle removing a factor
    const removeFactor = (factorToRemove: string) => {
        setFactors(factors.filter((factor) => factor !== factorToRemove));
    };

    // Handler for updating factor ranges
    const handleFactorRangeChange = (factor: string, key: 'low' | 'high', value: number) => {
        setFactorRanges((prevRanges) => ({
            ...prevRanges,
            [factor]: {
                ...prevRanges[factor],
                [key]: value,
            },
        }));
    };

    const generateDOE = async () => {
        setErrorMessage(''); // Clear any previous error
        try {
            const response = await axios.post(API_URL + '/generate_doe', {
                factors,
                doeType,
                factorRanges: factorRanges, // add your factorRanges as required
            });
            setExperimentRuns(response.data);
            setShowDOE(true);
            setRuns(response.data)
            setErrorMessage('')
        } catch (error) {
            const err = error as any; // Type assertion
            if (err.response && err.response.data.error) {
                setErrorMessage(err.response.data.error);
                setExperimentRuns([]);
                setRuns([])
            } else {
                setErrorMessage('An error occurred while generating the design.');
                setExperimentRuns([]);
                setRuns([])
            }
        }
    };


    const csvHeaders = [{ label: 'Run Number', key: 'runNumber' }, ...factors.map(factor => ({ label: factor, key: factor }))];
    const csvData = runs.map((run, index) => ({ runNumber: index + 1, ...(run as object) })); // Typecast run as object


    // Validate button enable state
    const isGenerateDisabled = (option) => {
        if (option === "Plackett-Burman Design (PBD)" && factors.length < 4) {
            return true;
        }
        else if (option === "Central Composite Design (CCD)" && factors.length < 3) {
            return true;
        }
        return factors.length < 2 || option === '';
    };

    return (
        <>
            <TitleBar title="Experiment Design" pageDescription='Design your experiments with proper DOEs.' />
            <ContentContainer>


                {/* Step 1: Define Factors */}
                <Typography variant="h6" gutterBottom>
                    1. Define Factors
                </Typography>
                <Box display="flex" gap={2} mb={2} width="100%">
                    <TextField
                        label="Enter a factor name"
                        value={factorName}
                        onChange={(e) => setFactorName(e.target.value)}
                        variant="outlined"
                        sx={{ flexBasis: '85%' }}  // Set width proportion for TextField
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addFactor}
                        sx={{ flexBasis: '15%', minWidth: '130px' }}  // Set width proportion and minimum width for Button
                    >
                        Add Factor
                    </Button>
                </Box>

                {/* Display the list of added factors */}
                {factors.length > 0 && (
                    <Box mb={4}>
                        <Typography variant="subtitle1" gutterBottom>
                            Current Factors:
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {factors.map((factor, index) => (
                                <Chip
                                    key={factor}
                                    label={`${factor}`}
                                    onDelete={() => removeFactor(factor)}
                                    color="primary"
                                    variant="outlined"
                                    sx={{ fontSize: '0.875rem', padding: '4px 8px' }}
                                />
                            ))}
                        </Box>
                    </Box>
                )}
                <Divider />
                <Typography variant="h6" gutterBottom mt={4} mb={4}>
                    2. Choose a DOE design
                </Typography>
                <Collapse in={factors.length > 1} timeout="auto" unmountOnExit>
                    <FormControl component="fieldset" sx={{ mb: 4 }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {doeOptions.map((option) => (
                                <Tooltip
                                    key={option}
                                    title={option === "Plackett-Burman Design (PBD)" && factors.length < 4
                                        ? "Requires at least 4 factors"
                                        : doeTooltips[option]
                                    }
                                    arrow
                                >
                                    <span> {/* Wrapper to enable tooltip on disabled items */}
                                        <Chip
                                            label={option}
                                            onClick={() => handleDoeTypeChange(option)}
                                            color={doeType === option ? 'primary' : 'default'}
                                            variant={doeType === option ? 'filled' : 'outlined'}
                                            sx={{ cursor: isGenerateDisabled(option) ? 'not-allowed' : 'pointer', padding: '4px 8px', fontSize: '0.875rem' }}
                                            disabled={isGenerateDisabled(option)}
                                        />
                                    </span>
                                </Tooltip>
                            ))}
                        </Box>
                    </FormControl>
                </Collapse>
                <Divider />
                <Typography variant="h6" gutterBottom mt={4} mb={4}>
                    3. Set High and Low Values for Each Factor
                </Typography>
                {/* Collapse component that only opens when factors are defined and DOE type is selected */}
                <Collapse in={factors.length > 1 && doeType !== ''} timeout="auto" unmountOnExit>

                    {factors.map((factor) => (
                        <Grid container spacing={2} key={factor} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label={`Low value for ${factor}`}
                                    type="number"
                                    value={factorRanges[factor]?.low ?? 0}
                                    onChange={(e) => handleFactorRangeChange(factor, 'low', parseFloat(e.target.value))}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label={`High value for ${factor}`}
                                    type="number"
                                    value={factorRanges[factor]?.high ?? 1}
                                    onChange={(e) => handleFactorRangeChange(factor, 'high', parseFloat(e.target.value))}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Collapse>

                <Divider />
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} mb={4}>
                    <Typography variant="h6" gutterBottom>
                        4. Generate Experimental Runs
                    </Typography>

                    {factors.length > 1 && (
                        <Box display="flex" alignItems="center" gap={2}>
                            <CSVLink
                                data={csvData}
                                headers={csvHeaders}
                                filename="experimental_runs.csv"
                                style={{ textDecoration: 'none' }}

                            >
                                <Button disabled={!showDOE} variant="contained" color="secondary">
                                    Download CSV
                                </Button>
                            </CSVLink>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={generateDOE}
                                disabled={isGenerateDisabled(doeType)}
                            >
                                Generate
                            </Button>
                        </Box>
                    )}
                </Box>

                {errorMessage && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Alert>
                )}

                <Collapse in={showDOE} timeout="auto" unmountOnExit>
                    <Box mb={2}>
                        <FormControlLabel
                            control={<Checkbox checked={randomize} onChange={handleRandomizeChange} color="primary" />}
                            label="Randomize Order"
                        />
                    </Box>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                    <TableCell>Run Number</TableCell>
                                    {factors.map((factor) => (
                                        <TableCell key={factor}>{factor}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {runs.map((run, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        {factors.map((factor) => (
                                            <TableCell key={factor}>{run[factor]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>
            </ContentContainer>


        </>
    );
};

export default ExperimentDesign;
