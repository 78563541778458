// DatasetUploader.js

import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Collapse,
    Alert,
    Divider,
    Chip
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileUpload from '../components/FileUpload';
import { theme } from '../styles/theme';

const DatasetUploader = ({
    fileName,
    setFileName,
    handleFileUpload,
    errorMessage,
    data,
    numericColumns,
    selectedColumns,
    toggleColumnSelection,
    showFileUpload,
    setShowFileUpload,
    selectColumnsText
}) => {

    const sortedColumns = [...numericColumns].sort((a, b) => {
        const aSelected = selectedColumns.includes(a);
        const bSelected = selectedColumns.includes(b);
        return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });

    return (
        <Box sx={{ padding: '0px', background: theme.palette.background.paper }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={showFileUpload ? 0 : 4}>
                <Box display="flex" alignItems="center" gap={2}>
                    {!fileName ? (
                        <Typography variant="h6" gutterBottom>
                            Upload Your Dataset (CSV or XLSX)
                        </Typography>
                    ) : (
                        <Typography variant="h6" gutterBottom>
                            Uploaded file: {fileName}
                        </Typography>
                    )}
                </Box>

                {fileName && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowFileUpload(!showFileUpload)}
                        endIcon={showFileUpload ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                        {showFileUpload ? 'Hide' : 'Analyze Different File'}
                    </Button>
                )}
            </Box>

            <Collapse in={showFileUpload} timeout="auto" unmountOnExit>
                {showFileUpload && <FileUpload handleFileUpload={handleFileUpload} />}
            </Collapse>

            {errorMessage && (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}

            <Divider />

            <Typography variant="h6" mt={4} mb={data.length > 0 && numericColumns.length ? 0 : 4} gutterBottom>
                {selectColumnsText}
            </Typography>

            {data.length > 0 && numericColumns.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, mb: 4 }}>
                    {sortedColumns.map((column) => {
                        const isSelected = selectedColumns.includes(column);
                        return (
                            <Chip
                                key={column}
                                label={column}
                                onDelete={() => toggleColumnSelection(column)}
                                sx={{
                                    backgroundColor: isSelected ? 'primary.main' : 'grey.300',
                                    color: isSelected ? 'white' : 'black',
                                    '&:hover': {
                                        backgroundColor: isSelected ? 'red' : 'green',
                                    },
                                    '& .MuiChip-deleteIcon': { color: 'white' },
                                }}
                                onClick={() => toggleColumnSelection(column)}
                                deleteIcon={isSelected ? undefined : <span />}
                            />
                        );
                    })}
                </Box>
            )}

            <Divider />
        </Box>
    );
};

export default DatasetUploader;
