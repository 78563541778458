// helperFunctions.js

// Calculates the high and low values for each column
export function getHighLowValues(data, columns) {
    return columns.reduce((acc, col) => {
        const values = data.map(row => row[col]);
        acc[col] = {
            high: Math.max(...values),
            low: Math.min(...values),
        };
        return acc;
    }, {});
}

// Generates a full factorial of all high-low ranges for each column
export function generateFullFactorial(highLow) {
    const keys = Object.keys(highLow);
    const cartesian = (arr) =>
        arr.reduce((a, b) => a.flatMap((d) => b.map((e) => [...d, e])), [[]]);
    
    const ranges = keys.map((key) => [highLow[key].low, highLow[key].high]);
    const combinations = cartesian(ranges);
    
    return combinations.map((combo) =>
        keys.reduce((obj, key, i) => ({ ...obj, [key]: combo[i] }), {})
    );
}

// Calculates the Euclidean distance between two points
export function calculateEuclideanDistance(point1, point2) {
    const keys = Object.keys(point1);
    const values1 = keys.map((key) => Number(point1[key]));
    const values2 = keys.map((key) => Number(point2[key]));

    const squaredDifferences = values1.map((val, i) => Math.pow(val - values2[i], 2));
    return Math.sqrt(squaredDifferences.reduce((acc, curr) => acc + curr, 0));
}

// Normalizes data based on high-low values and selected columns
export function normalizeData(data, highLow, selectedColumns) {
    return data.map((row) =>
        Object.fromEntries(
            Object.entries(row)
                .filter(([key]) => selectedColumns.includes(key))
                .map(([key, value]) => [
                    key,
                    typeof value === "number" && highLow[key]
                        ? (value - highLow[key].low) / (highLow[key].high - highLow[key].low)
                        : value,
                ])
        )
    );
}
