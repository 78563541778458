import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Chip } from '@mui/material';

interface ParallelCoordinatesPlotProps {
    data: Array<{ [key: string]: any }>;
    selectedColumns: string[];
}

const ParallelCoordinatesPlot: React.FC<ParallelCoordinatesPlotProps> = ({ data, selectedColumns }) => {
    const [firstColumn, setFirstColumn] = useState(selectedColumns[0]);

    const handleFirstColumnChange = (column: string) => {
        setFirstColumn(column);
    };

    useEffect(() => {
        if (!selectedColumns.includes(firstColumn)) {
            setFirstColumn(selectedColumns[0])
        }
    }, [])

    const unpack = (rows: any[], key: string) => rows.map(row => row[key]);

    // Define plotData with 'dimensions' using `as any` type assertion
    const plotData: any[] = [
        {
            type: 'parcoords',
            line: {
                color: unpack(data, firstColumn),
            },
            colorscale: 'Jet',
            dimensions: selectedColumns.map((col) => ({
                label: col,
                values: unpack(data, col),
                range: [Math.min(...unpack(data, col)), Math.max(...unpack(data, col))],
            })),
        },
    ];

    // Layout definition for the plot
    const layout = {
        height: 400,
        margin: { l: 50, r: 50, t: 50, b: 50 },
        autosize: true, // Adjust to container width
    };

    return (
        <Box>
            {/* <FormControl component="fieldset" fullWidth>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {selectedColumns.map((col) => (
                        <Chip
                            key={col}
                            label={col}
                            onClick={() => handleFirstColumnChange(col)}
                            color={firstColumn === col ? 'primary' : 'default'}
                            variant={firstColumn === col ? 'filled' : 'outlined'}
                            sx={{ cursor: 'pointer', padding: '4px 8px', fontSize: '0.875rem' }}
                        />
                    ))}
                </Box>
            </FormControl> */}
            {/* Plotly parallel coordinates plot */}
            <Plot
                data={plotData}
                layout={layout}
                useResizeHandler={true} // Enables responsiveness
                style={{ width: '100%', height: '400px' }} // Ensures the plot container uses full width
            />
        </Box>
    );
};

export default ParallelCoordinatesPlot;
