import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const FileUpload = ({ handleFileUpload }) => {
    const [uploadedFile, setUploadedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFile(file);
            handleFileUpload(event); // Pass file event to the parent handler
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, mb: 4 }}>
            {/* <Typography variant="h6" gutterBottom>
                Upload Your Dataset
            </Typography> */}
            <input
                type="file"
                accept=".csv,.tsv,.xlsx"
                onChange={handleFileChange}
                style={{ display: 'none' }} // Hide default input field
                id="file-upload"
            />
            <label htmlFor="file-upload">
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<UploadFileIcon />}
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        px: 3, // Padding for width
                        py: 1.5, // Padding for height
                    }}
                >
                    {uploadedFile ? 'Select Different File' : 'Select File'}
                </Button>
            </label>

            {uploadedFile && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Current file: {uploadedFile.name}
                </Typography>
            )}

            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                Accepted formats: .csv, .tsv, .xlsx
            </Typography>
        </Box>
    );
};

export default FileUpload;
