import React, { useState } from 'react';
import { Box, Typography, Divider, TextField, IconButton, List, ListItem } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { theme } from '../styles/theme';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../utils/constants';
import ReactMarkdown from 'react-markdown';
import { useExperiment } from '../contexts/ExperimentContext';



const ChatbotSidebar = () => {
    const [messages, setMessages] = useState<{ user: string, text: string }[]>([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const location = useLocation();
    const pageTitles: { [key: string]: string } = {
        '/': 'Home',
        '/experiment-design': 'Experiment Design',
        '/parameter-space-exploration': 'Parameter Space Exploration',
        '/data-analysis': 'Data Analysis',
        '/machine-modeling-optimization': 'ML Modeling and Optimization',
    };


    const { factors } = useExperiment();
    
    const currentPage = pageTitles[location.pathname] || 'Unknown Page';


    const handleSendMessage = async () => {
        if(currentPage === 'Experiment Design'){
            handleSendMessageExpDesign()
        }else{
            handleSendMessageGeneral()
        }
    }


    const handleSendMessageExpDesign = async () => {
        if (input.trim()) {
            const userMessage = { user: 'User', text: input };

            // Get the last 5 user messages, formatted as a single string
            const pastUserMessages = messages
            .filter(msg => msg.user === 'User') // Only include user messages
            .slice(-5)
            .map((msg, index) => `${index + 1}). ${msg.text}`)
            .join(' ');

            const pastUserMessageCounts = messages
            .filter(msg => msg.user === 'User') // Only include user messages
            .slice(-5)
            .length
                    
            setMessages((prevMessages) => [...prevMessages, userMessage]); // Add user message
            setInput('');
            setIsLoading(true);



            try {
                const response = await fetch(API_URL + '/api/chatExp', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        message: input, 
                        page: currentPage,
                        factorCount: factors.length,
                        factorNames: factors.join(', '),
                        pastUserMessages: pastUserMessages, 
                        pastUserMessageCounts: pastUserMessageCounts
                    }),

                });

                if (!response.ok) throw new Error('Error communicating with the backend');

                if (response.body) {
                    const reader = response.body.getReader();
                    let receivedText = '';
                    const decoder = new TextDecoder();
                    setIsLoading(false)
                    // Temporary message for AI Assistant to update in chunks
                    setMessages((prevMessages) => [...prevMessages, { user: 'AI Assistant', text: 'Typing...' }]);

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        const chunk = decoder.decode(value);
                        receivedText += chunk;

                        // Update the last message (AI Assistant's message) with the received text
                        setMessages((prevMessages) => [
                            ...prevMessages.slice(0, -1),
                            { user: 'AI Assistant', text: receivedText }
                        ]);
                    }
                } else {
                    console.error('Response body is null');
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };


    const handleSendMessageGeneral = async () => {
        if (input.trim()) {
            const userMessage = { user: 'User', text: input };
            setMessages((prevMessages) => [...prevMessages, userMessage]); // Add user message
            setInput('');
            setIsLoading(true);

            try {
                const response = await fetch(API_URL + '/api/chat', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ message: input, page: currentPage }),
                });

                if (!response.ok) throw new Error('Error communicating with the backend');

                if (response.body) {
                    const reader = response.body.getReader();
                    let receivedText = '';
                    const decoder = new TextDecoder();
                    setIsLoading(false)
                    // Temporary message for AI Assistant to update in chunks
                    setMessages((prevMessages) => [...prevMessages, { user: 'AI Assistant', text: 'Typing...' }]);

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        const chunk = decoder.decode(value);
                        receivedText += chunk;

                        // Update the last message (AI Assistant's message) with the received text
                        setMessages((prevMessages) => [
                            ...prevMessages.slice(0, -1),
                            { user: 'AI Assistant', text: receivedText }
                        ]);
                    }
                } else {
                    console.error('Response body is null');
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Box
            sx={{
                width: 380,
                backgroundColor: theme.palette.grey[200],
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                position: 'fixed',
                right: 0,
                top: 0,
                pt: 6,
            }}
        >
            <Typography variant="h6" align="center" sx={{ mt: 2, mb: 0, pb:2, pt:2, color: "#fff", fontWeight: "bold", background: theme.palette.grey[900] }}>
                AI Assistant
            </Typography>
            <Divider sx={{ mt: 0 }} />

            {/* Chat history */}
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 1 }}>
                <List>
                    {messages.map((message, index) => (
                        <ListItem 
                            key={index} 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: message.user === 'User' ? 'flex-end' : 'flex-start',
                                mb: 1 
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    alignSelf: message.user === 'User' ? 'flex-end' : 'flex-start',
                                    color: message.user === 'User' ? theme.palette.primary.main : theme.palette.secondary.main,
                                    fontWeight: 'bold',
                                    mb: 0.5, 
                                    fontSize: 16
                                }}
                            >
                                {message.user}
                            </Typography>
                            <Box
                                sx={{
                                    maxWidth: '100%',
                                    paddingLeft: 1.5,
                                    paddingRight: 1.5,
                                    borderRadius: 2,
                                    bgcolor: message.user === 'User' ? theme.palette.primary.light : theme.palette.grey[300],
                                    color: message.user === 'User' ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                    display: 'inline-block'
                                }}
                            >
                                <ReactMarkdown>{message.text}</ReactMarkdown>
                            </Box>
                        </ListItem>
                    ))}
                    {isLoading && (
                        <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start', mb: 1 }}>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: theme.palette.secondary.main,
                                    fontWeight: 'bold',
                                    mb: 0.5,
                                    fontSize: 16
                                }}
                            >
                                AI Assistant
                            </Typography>
                            <Box
                                sx={{
                                    maxWidth: '100%',
                                    p: 1.5,
                                    borderRadius: 2,
                                    bgcolor: theme.palette.grey[300],
                                    color: theme.palette.text.primary,
                                    display: 'inline-block'
                                }}
                            >
                                <Typography variant="body2">Thinking...</Typography>
                            </Box>
                        </ListItem>
                    )}
                </List>
            </Box>

            {/* Input area */}
            <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }} sx={{ display: 'flex', p: 2 }}>
                <TextField
                    variant="outlined"
                    placeholder="Type a message..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    sx={{ flexGrow: 1 }}
                />
                <IconButton color="primary" onClick={handleSendMessage} disabled={!input.trim()}>
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ChatbotSidebar;
