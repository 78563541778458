import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface TitleBarProps {
    title: string;
    pageDescription: string;
}

const TitleBar: React.FC<TitleBarProps> = ({ title, pageDescription }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.paper,
                padding: '16px 24px',
                borderTopLeftRadius: '8px', // Only top left corner
                borderTopRightRadius: '8px', // Only top right corner
                marginBottom: '0px',
                borderBottom: `2px solid ${theme.palette.grey[300]}`, // Light grey border
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', // Align title left and description right
            }}
        >
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                {title}
            </Typography>
            
            <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                {pageDescription}
            </Typography>
        </Box>
    );
};

export default TitleBar;
