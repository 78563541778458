import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { theme } from '../styles/theme';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ParameterRangeGraphsProps {
    data: Array<{ [key: string]: any }>;
    selectedColumns: string[];
}

const ParameterRangeGraphs: React.FC<ParameterRangeGraphsProps> = ({ data, selectedColumns }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const chartsPerPage = 3; // Number of charts to show per "page"

    // Generate pairs of columns
    const columnPairs = selectedColumns.flatMap((col, i) => 
        selectedColumns.slice(i + 1).map((pairCol) => [col, pairCol])
    );

    // Helper function to generate data for scatter plots based on column pairs
    const getScatterData = (xColumn: string, yColumn: string) => {
        const xValues = data.map((row) => row[xColumn]);
        const yValues = data.map((row) => row[yColumn]);

        return {
            labels: data.map((_, idx) => `Point ${idx + 1}`),
            datasets: [
                {
                    label: `${xColumn} vs ${yColumn}`,
                    data: xValues.map((x, idx) => ({ x, y: yValues[idx] })),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                },
            ],
        };
    };

    // Calculate start and end indexes for the current page
    const startIdx = currentPage * chartsPerPage;
    const endIdx = startIdx + chartsPerPage;
    const totalPages = Math.ceil(columnPairs.length / chartsPerPage);

    // Navigation functions
    const handlePrevious = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
    };

    const justifyContentStyle = columnPairs.slice(startIdx, endIdx).length < 3 ? 'flex-start' : 'space-between';

    useEffect(() => {
        setCurrentPage(0);
    }, [selectedColumns]);

    return (
        <Box sx={{ width: '100%', padding: '20px' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    justifyContent: justifyContentStyle,
                    width: '100%',
                }}
            >
                {columnPairs.slice(startIdx, endIdx).map(([xColumn, yColumn], idx) => (
                    <Paper
                        key={`${xColumn}-${yColumn}`}
                        sx={{
                            minWidth: '280px',
                            maxWidth: '340px',
                            width: '100%',
                            height: '400px',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="subtitle1" align="center" gutterBottom>
                            {`${xColumn} vs ${yColumn}`}
                        </Typography>
                        <Box sx={{ height: '300px' }}>
                            <Scatter
                                data={getScatterData(xColumn, yColumn)}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: { display: false },
                                    },
                                    scales: {
                                        x: {
                                            title: { display: true, text: xColumn },
                                        },
                                        y: {
                                            title: { display: true, text: yColumn },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                    variant="contained"
                    onClick={handlePrevious}
                    disabled={currentPage === 0}
                    sx={{ mr: 1 }}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={currentPage >= totalPages - 1}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default ParameterRangeGraphs;
