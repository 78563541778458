import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Select, MenuItem, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../utils/constants';

const ParameterOptimization = ({ independentVars, dependentVar, data, selectedModel }) => {
    const [parameterRanges, setParameterRanges] = useState({});
    const [targetType, setTargetType] = useState("Maximize");
    const [targetValue, setTargetValue] = useState(null);
    const [optimalValues, setOptimalValues] = useState(null);
    const [loading, setLoading] = useState(false);

    // Initialize parameter ranges with min/max from data
    useEffect(() => {
        const initialRanges = {};
        independentVars.forEach((varName) => {
            const values = data.map(row => row[varName]);
            initialRanges[varName] = { min: Math.min(...values), max: Math.max(...values) };
        });
        setParameterRanges(initialRanges);
    }, [independentVars, data]);

    // Handle parameter range changes
    const handleRangeChange = (varName, type, value) => {
        setParameterRanges((prev) => ({
            ...prev,
            [varName]: { ...prev[varName], [type]: parseFloat(value) },
        }));
    };

    // Handle optimization request
    const handleOptimization = async () => {
        setLoading(true);
        setOptimalValues(null);
        try {
            const response = await axios.post(API_URL + "/api/find_optimal_values", {
                data,
                parameterRanges,
                targetType,
                targetValue,
                independentVars,
                dependentVar,
                modelType: selectedModel  // Include the selected model type
            });

            if (response.data.success) {
                setOptimalValues(response.data.optimalValues);
            } else {
                console.error("Optimization error:", response.data.error);
            }
        } catch (error) {
            console.error("Error in optimization request:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h5" gutterBottom>Define Parameter Ranges for Optimization</Typography>
            <Grid container spacing={2} mt={2}>
                {independentVars.map((varName) => (
                    <React.Fragment key={varName}>
                        <Grid item xs={6} md={6}>
                            <TextField
                                label={`Min value for ${varName}`}
                                type="number"
                                value={parameterRanges[varName]?.min || ""}
                                onChange={(e) => handleRangeChange(varName, 'min', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                label={`Max value for ${varName}`}
                                type="number"
                                value={parameterRanges[varName]?.max || ""}
                                onChange={(e) => handleRangeChange(varName, 'max', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>

            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Optimization Objective</Typography>
                <Select
                    value={targetType}
                    onChange={(e) => setTargetType(e.target.value)}
                    fullWidth
                >
                    <MenuItem value="Maximize">Maximize</MenuItem>
                    <MenuItem value="Minimize">Minimize</MenuItem>
                    <MenuItem value="Target Specific Value">Target Specific Value</MenuItem>
                </Select>
            </Box>

            {targetType === "Target Specific Value" && (
                <Box sx={{ mt: 2 }}>
                    <TextField
                        label="Target Output Value"
                        type="number"
                        value={targetValue || ""}
                        onChange={(e) => setTargetValue(parseFloat(e.target.value))}
                        fullWidth
                    />
                </Box>
            )}

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOptimization}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Find Optimal Values for " + selectedModel + " model"}
                </Button>
            </Box>

            {optimalValues && (
                <Box
                    sx={{
                        mt: 4,
                        p: 3,
                        border: '2px solid gold',
                        borderRadius: '8px',
                        backgroundColor: 'rgba(255, 215, 0, 0.1)', // Light gold background
                        boxShadow: '0px 4px 12px rgba(255, 215, 0, 0.5)', // Soft glowing effect
                        textAlign: 'center', // Center text
                        position: 'relative'
                    }}
                >
                    <Typography variant="h5" sx={{ color: 'goldenrod', fontWeight: 'bold' }}>
                        Optimal Parameter Values
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'goldenrod', fontWeight: 'bold' }}>
                        Using {selectedModel} and finding optimal parameters that will {targetType} the {dependentVar} value
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {Object.keys(optimalValues).map((varName) => (
                            <Grid item xs={6} key={varName}>
                                <Typography sx={{ color: 'goldenrod' }}>
                                    <strong>{varName}</strong>: {optimalValues[varName].toFixed(3)}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

            )}
        </Box>
    );
};

export default ParameterOptimization;
