import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { theme } from '../styles/theme';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ParameterRangeGraphsBarProps {
    data: Array<{ [key: string]: any }>;
    selectedColumns: string[];
}

const ParameterRangeGraphsBar: React.FC<ParameterRangeGraphsBarProps> = ({ data, selectedColumns }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const chartsPerPage = 3; // Number of charts to show per "page"

    // Helper function to generate data for Bar chart based on the column values
    const getBarData = (column: string) => {
        const series = data.map((row) => row[column]);
        const valueCounts: { [key: string]: number } = {};

        series.forEach((value) => {
            valueCounts[value] = (valueCounts[value] || 0) + 1;
        });

        return {
            labels: Object.keys(valueCounts),
            datasets: [
                {
                    label: `${column} Distribution`,
                    data: Object.values(valueCounts),
                    backgroundColor: theme.palette.primary.main,
                },
            ],
        };
    };

    // Calculate the start and end indexes for the current page
    const startIdx = currentPage * chartsPerPage;
    const endIdx = startIdx + chartsPerPage;
    const totalPages = Math.ceil(selectedColumns.length / chartsPerPage);

    // Function to handle navigation
    const handlePrevious = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
    };

    // Determine the justification style based on the number of charts in the current page view
    const justifyContentStyle = selectedColumns.slice(startIdx, endIdx).length < 3
        ? 'flex-start' // Align left if fewer than 3 charts
        : 'space-between'; // Spread out if 3 or more charts

    const marginStyle = selectedColumns.slice(startIdx, endIdx).length < 3
        ? 10 // Align left if fewer than 3 charts
        : 0; // Spread out if 3 or more charts



    useEffect(() => {
        setCurrentPage(0)
    },[selectedColumns])

    return (
        <Box sx={{ width: '100%', padding: '20px' }}>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    justifyContent: justifyContentStyle, // Conditionally applied style
                    width: '100%',

                }}
            >
                {selectedColumns.slice(startIdx, endIdx).map((column, idx) => (
                    <Paper
                        key={idx}
                        sx={{
                            minWidth: '280px',
                            maxWidth: '340px',
                            width: '100%',
                            height: '400px',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            // marginLeft: marginStyle,
                            marginRight: marginStyle
                        }}
                    >
                        <Typography variant="subtitle1" align="center" gutterBottom>
                            {column} Value Distribution
                        </Typography>
                        <Box sx={{ height: '300px' }}>
                            <Bar
                                data={getBarData(column)}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: { display: false },
                                    },
                                    scales: {
                                        x: {
                                            title: { display: true, text: 'Value' },
                                            ticks: { maxTicksLimit: 5 },
                                        },
                                        y: {
                                            title: { display: true, text: 'Count' },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                    variant="contained"
                    onClick={handlePrevious}
                    disabled={currentPage === 0}
                    sx={{ mr: 1 }}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={currentPage >= totalPages - 1}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default ParameterRangeGraphsBar;
