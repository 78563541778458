// src/components/Login.tsx
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const handleLogin = () => {
    if (password === process.env.REACT_APP_PASSWORD) {
      Cookies.set('authenticated', 'true', { expires: 1 }); // Store cookie for 1 day
      onLogin();
    } else {
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#121212', // Dark background color
      }}
    >
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          width: '100%',
          maxWidth: 400,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#1e1e1e', // Darker box background color
          color: '#fff',
          borderRadius: '8px',
        }}
      >
                <Typography variant="h3" sx={{ mb: 2, color: '#fff' }}>
          Biomethod AI
        </Typography>
        <Typography variant="h5" sx={{ mb: 2, color: '#fff' }}>
          Please enter password to continue
        </Typography>
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={error}
          helperText={error ? 'Incorrect password' : ''}
          sx={{
            mt: 2,
            input: { color: '#fff' },
            '& .MuiFormLabel-root': { color: '#bbb' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: '#888' },
              '&.Mui-focused fieldset': { borderColor: '#fff' },
            },
          }}
          fullWidth
        />
        <Button
          variant="contained"
          onClick={handleLogin}
          sx={{
            mt: 3,
            backgroundColor: '#007aff',
            '&:hover': { backgroundColor: '#005bb5' },
            color: '#fff',
            width: '100%',
          }}
        >
          Login
        </Button>
      </Paper>
    </Box>
  );
};

export default Login;
