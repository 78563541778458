import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, Tooltip, styled, TooltipProps } from '@mui/material';
import TitleBar from '../components/Titlebar';
import ContentContainer from '../components/ContentContainer';
import DatasetUploader from '../components/DatasetUploader';
import { theme } from '../styles/theme';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { API_URL } from '../utils/constants';
import RegressionAnalysis from '../components/RegressionAnalyses';
import RFECVChart from '../components/RFECVChart';



const DataAnalysis = () => {
    const [fileName, setFileName] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [data, setData] = useState([]);
    const [numericColumns, setNumericColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [showFileUpload, setShowFileUpload] = useState(true);
    const [dependentVar, setDependentVar] = useState(null);
    const [analysisResults, setAnalysisResults] = useState(null);
    const [tabValue, setTabValue] = useState(0);

    // Toggle tab selection
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const toggleColumnSelection = (column) => {
        setSelectedColumns((prevSelected) =>
            prevSelected.includes(column) ? prevSelected.filter((col) => col !== column) : [...prevSelected, column]
        );
    };

    // Handle file upload
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
        setFileName(file.name); // Set the file name in the state

        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (fileExtension === 'csv' || fileExtension === 'tsv') {
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                delimiter: fileExtension === 'tsv' ? '\t' : ',',
                complete: (results: any) => processFileData(results.data),
                error: (error) => console.error("Error parsing file:", error),
            });
        } else if (fileExtension === 'xlsx') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result;
                if (result) {
                    const data = new Uint8Array(result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                    processFileData(jsonData);
                } else {
                    console.error("File read result is null.");
                }
            };
            reader.onerror = () => console.error("Error reading file.");
            reader.readAsArrayBuffer(file);
        } else {
            setErrorMessage('Unsupported file format. Please upload a CSV, TSV, or XLSX file.');
        }
    };

    const processFileData = (fileData) => {
        setData(fileData);
        const sampleRow = fileData[0];
        const numericCols = Object.keys(sampleRow).filter((col) => typeof sampleRow[col] === 'number');
        setNumericColumns(numericCols);
        setSelectedColumns(numericCols);
        setErrorMessage('');
        setShowFileUpload(false);
    };

    const fetchAnalysisResults = async () => {
        try {
            const response = await axios.post(API_URL + '/api/perform_analysis', {
                data: data,
                independent_vars: selectedColumns,
                dependent_var: dependentVar
            });
            console.log(response.data);
            setAnalysisResults(response.data);
        } catch (error) {
            console.error("Error fetching analysis results:", error);
        }
    };

    useEffect(() => {
        if (dependentVar && selectedColumns.length > 0 && data.length > 0) {
            fetchAnalysisResults();
            console.log("FETCH")
        }
    }, [dependentVar, selectedColumns]);


// Customize the Tooltip with larger font size
const LargeTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .MuiTooltip-tooltip`]: {
        fontSize: '1rem', // Adjust font size as needed
        maxWidth: 220, // Optional: control tooltip width
    },
});
    return (
        <Box sx={{ padding: '0px', background: theme.palette.background.paper }}>
            <TitleBar title="Data Analysis" pageDescription="Analyze your data" />
            <ContentContainer>
                <DatasetUploader
                    fileName={fileName}
                    setFileName={setFileName}
                    handleFileUpload={handleFileUpload}
                    errorMessage={errorMessage}
                    data={data}
                    numericColumns={numericColumns}
                    selectedColumns={selectedColumns}
                    toggleColumnSelection={toggleColumnSelection}
                    showFileUpload={showFileUpload}
                    setShowFileUpload={setShowFileUpload}
                    selectColumnsText={"Select Independent Variables"}
                />

                <FormControl fullWidth sx={{ mt: 4, mb: 2 }}>
                    <InputLabel>Select Dependent Variable</InputLabel>
                    <Select
                        value={dependentVar}
                        onChange={(e) => setDependentVar(e.target.value)}
                        label="Select Dependent Variable"
                    >
                        {numericColumns.map((col) => (
                            <MenuItem key={col} value={col}>
                                {col}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedColumns.length > 0 && dependentVar && (
                    <>

                        <Tabs value={tabValue} onChange={handleTabChange}             variant="scrollable"
 >
        <LargeTooltip title="ANOVA helps in identifying variables with the most significant differences between groups, useful in filtering relevant features for predictive models.">
            <Tab label="ANOVA" />
        </LargeTooltip>
        
        <LargeTooltip title="Linear Regression examines relationships between features and target variables. It provides insights into how much each feature contributes to predictions, aiding in feature importance evaluation.">
            <Tab label="Linear Regression" />
        </LargeTooltip>
        
        <LargeTooltip title="RFECV (Recursive Feature Elimination with Cross-Validation) ranks features by iteratively removing the least important ones to find the optimal subset, essential for reducing model complexity without losing predictive power.">
            <Tab label="RFECV Feature Selection" />
        </LargeTooltip>
        
        <LargeTooltip title="Variance Inflation Factors (VIF) detect multicollinearity among features. Features with high VIFs may be redundant, guiding the removal of features that introduce noise or redundancy.">
            <Tab label="Variance Inflation Factors" />
        </LargeTooltip>
        
        <LargeTooltip title="Ridge Regression adds a penalty to high coefficients to handle multicollinearity, helping identify and shrink the impact of irrelevant features for stable predictions.">
            <Tab label="Ridge Regression Coefficients" />
        </LargeTooltip>
        
        <LargeTooltip title="Lasso Regression performs feature selection by setting some coefficients to zero, effectively filtering out less important features for model simplicity and interpretability.">
            <Tab label="Lasso Regression Coefficients" />
        </LargeTooltip>
    </Tabs>
                        {/* ANOVA Analysis Tab */}
                        {tabValue === 0 && analysisResults && (

                            <Box mt={4} mb={4}>
                                {/* <Typography>R-squared: {anovaResults.r_squared.toFixed(4)}</Typography> */}
                                <TableContainer component={Paper} sx={{ maxHeight: 600, overflowY: 'auto' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Variable</TableCell>
                                                <TableCell>Sum of Squares</TableCell>
                                                <TableCell>Mean Square</TableCell>
                                                <TableCell>F-value</TableCell>
                                                <TableCell>p-value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analysisResults.anova_results.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{row.variable}</TableCell> {/* Updated to show actual variable */}
                                                    <TableCell>{row.sum_sq ? row.sum_sq.toFixed(4) : "N/A"}</TableCell>
                                                    <TableCell>{row.mean_sq ? row.mean_sq.toFixed(4) : "N/A"}</TableCell>
                                                    <TableCell>{row.F ? row.F.toFixed(4) : "N/A"}</TableCell>
                                                    <TableCell>{row['PR(>F)'] ? row['PR(>F)'].toFixed(4) : "N/A"}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                        )}

                        {/* Regression Tab */}
                        {tabValue === 1 && (
                            <Box>
                                <RegressionAnalysis data={data} independentVars={selectedColumns} dependentVar={dependentVar} />
                            </Box>
                        )}

                        {tabValue === 2 && (
                            <Box>
                                <Typography variant="h6">Optimal number of features: {analysisResults.rfecv.n_features}</Typography>
                                <Typography variant="body1">Selected features: {analysisResults.rfecv.optimal_features.join(', ')}</Typography>

                                {/* Render the RFECVChart with cvScores */}
                                <Box mt={4}>
                                    <RFECVChart cvScores={analysisResults.rfecv.cv_scores} />
                                </Box>

                                {/* Display Feature Coefficients */}
                                <Typography variant="h6">Feature Coefficients for Selected Features:</Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Feature</TableCell>
                                            <TableCell>Coefficient</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {analysisResults.rfecv.feature_coefficients.map((fc) => (
                                            <TableRow key={fc.feature}>
                                                <TableCell>{fc.feature}</TableCell>
                                                <TableCell>{fc.coefficient}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        )}


                        {tabValue === 3 && (
                            <Box>

                                <Typography variant="h6" mt={4}>Variance Inflation Factor (VIF)</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Variable</TableCell>
                                                <TableCell>VIF</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analysisResults?.vif_results.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{row.variable}</TableCell>
                                                    <TableCell>{row.VIF.toFixed(4)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>
                        )}

                        {/* Ridge Regression Coefficients */}
                        {tabValue === 4 && (
                            <Box>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Variable</TableCell>
                                                <TableCell>Coefficient</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analysisResults?.ridge_coefficients.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{row.variable}</TableCell>
                                                    <TableCell>{row.coefficient.toFixed(4)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        {/* Lasso Regression Coefficients */}
                        {tabValue === 5 && (
                            <Box>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Variable</TableCell>
                                                <TableCell>Coefficient</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {analysisResults?.lasso_coefficients.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{row.variable}</TableCell>
                                                    <TableCell>{row.coefficient.toFixed(4)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    </>
                )}



            </ContentContainer>
        </Box>
    );
};

export default DataAnalysis;
