import React, { useState } from 'react';
import { Box, Typography, Chip, Divider, Tooltip, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress, Grid } from '@mui/material';
import TitleBar from '../components/Titlebar';
import ContentContainer from '../components/ContentContainer';
import DatasetUploader from '../components/DatasetUploader';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { theme } from '../styles/theme';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { Chart, Line, Scatter } from 'react-chartjs-2';
import ParameterOptimization from '../components/ParameterOptimization';


const MachineModelingOptimization = () => {
    const [fileName, setFileName] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [data, setData] = useState([]);
    const [numericColumns, setNumericColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [showFileUpload, setShowFileUpload] = useState(true);
    const [dependentVar, setDependentVar] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(false);

    const [modelLastRan, setModelLastRan] = useState('')
    const [actualVsPredicted, setActualVsPredicted] = useState(null); // Add actual vs predicted state

    // Updated ML model options with GPR and RSM
    const mlModelOptions = [
        { name: 'Response Surface Methodology (RSM)', tooltip: 'Ideal for optimizing continuous processes with polynomial surfaces.' },
        { name: 'Artificial Neural Network (ANN)', tooltip: 'Suitable for complex, non-linear relationships.' },
        { name: 'Support Vector Machine (SVM)', tooltip: 'Best for classification tasks with clear boundaries.' },
        { name: 'Random Forest', tooltip: 'Ensemble method useful for high-dimensional data.' },
        { name: 'XGBoost', tooltip: 'Boosting method optimized for performance and accuracy.' },
        { name: 'Gaussian Process Regression (GPR)', tooltip: 'Useful for modeling relationships with uncertainty estimates.' },
    ];

    const shouldDisableOption = (optionName) => {
        return optionName === 'XGBoost' && selectedColumns.length < 4;
    };

    const handleModelSelect = (modelName) => {
        if (!shouldDisableOption(modelName)) {
            setSelectedModel(modelName);
        }



    };

    const toggleColumnSelection = (column) => {
        setSelectedColumns((prevSelected) =>
            prevSelected.includes(column)
                ? prevSelected.filter((col) => col !== column)
                : [...prevSelected, column]
        );
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
        setFileName(file.name);

        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (fileExtension === 'csv' || fileExtension === 'tsv') {
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                delimiter: fileExtension === 'tsv' ? '\t' : ',',
                complete: (results: any) => processFileData(results.data),
                error: (error) => console.error("Error parsing file:", error),
            });
        } else if (fileExtension === 'xlsx') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result;
                if (result) {
                    const data = new Uint8Array(result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                    processFileData(jsonData);
                } else {
                    console.error("File read result is null.");
                }
            };
            reader.onerror = () => console.error("Error reading file.");
            reader.readAsArrayBuffer(file);
        } else {
            setErrorMessage('Unsupported file format. Please upload a CSV, TSV, or XLSX file.');
        }
    };

    const processFileData = (fileData) => {
        setData(fileData);
        const sampleRow = fileData[0];
        const numericCols = Object.keys(sampleRow).filter((col) => typeof sampleRow[col] === 'number');
        setNumericColumns(numericCols);
        setSelectedColumns(numericCols);
        setErrorMessage('');
        setShowFileUpload(false);
    };

    const handleRunAnalyses = async () => {
        setLoading(true);
        setMetrics(null);
        setActualVsPredicted(null);
        setModelLastRan(selectedModel)

        try {
            const payload = {
                data,
                independentVars: selectedColumns,
                dependentVar: dependentVar,
                modelType: selectedModel
            };

            const response = await axios.post(`${API_URL}/api/run_model_analysis`, payload);

            if (response.data.success) {
                setMetrics(response.data.metrics);
                setActualVsPredicted(response.data.actual_vs_predicted);
            } else {
                console.error("Error in analysis:", response.data.error);
            }
        } catch (error) {
            console.error("An error occurred during analysis:", error);
        } finally {
            setLoading(false);
        }
    };

    // Determine min and max values for the reference line
    const minValue = actualVsPredicted
        ? Math.min(...actualVsPredicted.actual, ...actualVsPredicted.predicted)
        : 0;
    const maxValue = actualVsPredicted
        ? Math.max(...actualVsPredicted.actual, ...actualVsPredicted.predicted)
        : 1;

    return (
        <Box sx={{ padding: '0px', background: theme.palette.background.paper }}>
            <TitleBar title="ML Modeling and Optimization" pageDescription="Model your data, find optimal input parameters" />
            <ContentContainer>
                <DatasetUploader
                    fileName={fileName}
                    setFileName={setFileName}
                    handleFileUpload={handleFileUpload}
                    errorMessage={errorMessage}
                    data={data}
                    numericColumns={numericColumns}
                    selectedColumns={selectedColumns}
                    toggleColumnSelection={toggleColumnSelection}
                    showFileUpload={showFileUpload}
                    setShowFileUpload={setShowFileUpload}
                    selectColumnsText={"Select Independent Variables"}
                />
                <Divider />
                <FormControl fullWidth sx={{ mt: 4, mb: 2 }}>
                    <InputLabel>Select Dependent Variable</InputLabel>
                    <Select
                        value={dependentVar}
                        onChange={(e) => setDependentVar(e.target.value)}
                        label="Select Dependent Variable"
                    >
                        {numericColumns.map((col) => (
                            <MenuItem key={col} value={col}>
                                {col}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Divider />

                {selectedColumns.length > 0 && data.length > 0 && (
                    <>
                        <Box display="flex" flexWrap="wrap" gap={1} mt={4} mb={4}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'left', mt: 0, mb: 0 }}>
                                <Typography variant="h6" gutterBottom>
                                    Choose Model Type
                                </Typography>
                            </Box>

                            {mlModelOptions.map((option) => (
                                <Tooltip
                                    key={option.name}
                                    title={shouldDisableOption(option.name)
                                        ? "This option requires additional factors."
                                        : option.tooltip}
                                    arrow
                                >
                                    <span>
                                        <Chip
                                            label={option.name}
                                            onClick={() => handleModelSelect(option.name)}
                                            color={selectedModel === option.name ? 'primary' : 'default'}
                                            variant={selectedModel === option.name ? 'filled' : 'outlined'}
                                            sx={{
                                                cursor: shouldDisableOption(option.name) ? 'not-allowed' : 'pointer',
                                                padding: '4px 8px',
                                                fontSize: '0.875rem',
                                            }}
                                            disabled={shouldDisableOption(option.name)}
                                        />
                                    </span>
                                </Tooltip>
                            ))}
                        </Box>
                        <Divider />

                        <Grid container spacing={2} sx={{ paddingTop: 2, paddingBottom: 4 }}>
                            {/* Column with width 4 */}
                            <Grid item xs={12} md={4}>
                                {selectedModel && dependentVar && (
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'left', mt: 0 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleRunAnalyses}
                                            disabled={loading || selectedModel == modelLastRan}
                                            sx={{
                                                padding: '12px 24px',
                                                fontSize: '1rem',
                                                fontWeight: 'bold',
                                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                                textTransform: 'none',
                                                '&:hover': {
                                                    backgroundColor: '#1565c0',
                                                },
                                            }}
                                        >
                                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Model and Evaluate'}
                                        </Button>
                                    </Box>
                                )}
                            </Grid>

                            <Grid item xs={12} md={8}>

                                {metrics && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: '1000px',
                                            mt: 0,
                                            p: 3,
                                            borderRadius: '8px',
                                            backgroundColor: '#f5f5f5',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <Typography variant="h6" align="center" gutterBottom>
                                            {modelLastRan} Results (5-Fold Cross-Validation)
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                        {Object.keys(metrics).map((key) => (
                                            <Box
                                                key={key}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    my: 1,
                                                    px: 2,
                                                }}
                                            >
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}:
                                                </Typography>
                                                <Typography variant="body1">
                                                    {typeof metrics[key] === 'number'
                                                        ? metrics[key].toFixed(3)
                                                        : JSON.stringify(metrics[key])}
                                                </Typography>
                                            </Box>
                                        ))}

                                        {/* Actual vs Predicted Scatter Plot */}
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="h6" align="center" gutterBottom>
                                            Actual vs Predicted
                                        </Typography>
                                        {actualVsPredicted && (

                                            <Chart
                                                type="scatter" // Use 'scatter' as the base type
                                                data={{
                                                    datasets: [
                                                        {
                                                            label: 'Actual vs Predicted',
                                                            data: actualVsPredicted.actual.map((actual, i) => ({
                                                                x: actual,
                                                                y: actualVsPredicted.predicted[i],
                                                            })),
                                                            backgroundColor: 'rgba(75,192,192,1)',
                                                        },
                                                        {
                                                            label: 'Perfect Prediction Line',
                                                            data: [
                                                                { x: minValue, y: minValue },
                                                                { x: maxValue, y: maxValue },
                                                            ],
                                                            borderColor: 'rgba(255, 99, 132, 0.7)',
                                                            borderWidth: 2,
                                                            borderDash: [5, 5],
                                                            fill: false,
                                                            pointRadius: 0, // Hide points on the line
                                                            showLine: true, // Ensures it renders as a line
                                                        },
                                                    ],
                                                }}
                                                options={{
                                                    responsive: true,
                                                    scales: {
                                                        x: {
                                                            title: { display: true, text: 'Actual Values' },
                                                        },
                                                        y: {
                                                            title: { display: true, text: 'Predicted Values' },
                                                        },
                                                    },
                                                    plugins: {
                                                        tooltip: {
                                                            callbacks: {
                                                                label: (context) => {
                                                                    const point = context.raw as { x: number; y: number };
                                                                    return `Actual: ${point.x.toFixed(2)}, Predicted: ${point.y.toFixed(2)}`;
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Divider />

                        {metrics &&
                            <ParameterOptimization independentVars={selectedColumns} dependentVar={dependentVar} data={data} selectedModel={modelLastRan} />

                        }
                    </>
                )}
            </ContentContainer>
        </Box>
    );
};

export default MachineModelingOptimization;
