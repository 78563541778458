// export const theme = {

// }


import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',         // Main primary color (blue)
            light: '#42a5f5',        // Lighter shade for user messages
            dark: '#1565c0',         // Darker shade, if needed for accents
            contrastText: '#ffffff', // Text color on primary backgrounds
        },
        secondary: {
            main: '#ff4081',         // Secondary color (pinkish tone)
            light: '#ff79b0',        // Lighter shade, if needed
            dark: '#c60055',         // Darker shade for contrast
            contrastText: '#ffffff', // Text color on secondary backgrounds
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',         // Darkest grey for backgrounds and shadows
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161',
        },
        background: {
            default: '#f4f6f8',     // Light background color for the app
            paper: '#ffffff',       // White background for elements
        },
        text: {
            primary: '#333333',     // Dark text color for high contrast
            secondary: '#757575',   // Secondary text color for less contrast
            disabled: '#9e9e9e',    // Disabled text
        },
        success: {
            main: '#4caf50',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ff9800',
        },
        info: {
            main: '#2196f3',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
    },

    // Optionally add custom typography, spacing, etc., if needed
});


// white: "#fff",
// lightGrey: "#f2f2f2",
// grey: "#aaa",
// darkGrey: "#444"