import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ContentContainer from '../components/ContentContainer';
import { theme } from '../styles/theme';
import ScienceIcon from '@mui/icons-material/Science';
import ExploreIcon from '@mui/icons-material/Explore';
import BarChartIcon from '@mui/icons-material/BarChart';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

const nodes = [
  {
    id: '1',
    title: 'Experiment Design',
    description: 'Plan and structure effective experiments for insightful data.',
    color: '#FFD54F',
    hoverColor: '#FFC107',
    path: '/experiment-design',
    icon: <ScienceIcon fontSize="large" sx={{ color: '#333' }} />,
  },
  {
    id: '2',
    title: 'Parameter Space Exploration',
    description: 'Discover optimal settings to maximize performance outcomes.',
    color: '#4FC3F7',
    hoverColor: '#29B6F6',
    path: '/parameter-space-exploration',
    icon: <ExploreIcon fontSize="large" sx={{ color: '#333' }} />,
  },
  {
    id: '3',
    title: 'Data Analysis',
    description: 'Analyze datasets to reveal trends, insights, and patterns.',
    color: '#AED581',
    hoverColor: '#8BC34A',
    path: '/data-analysis',
    icon: <BarChartIcon fontSize="large" sx={{ color: '#333' }} />,
  },
  {
    id: '4',
    title: 'Machine Modeling & Optimization',
    description: 'Develop predictive models and optimize their accuracy.',
    color: '#FF8A65',
    hoverColor: '#FF7043',
    path: '/machine-modeling-optimization',
    icon: <ModelTrainingIcon fontSize="large" sx={{ color: '#333' }} />,
  },
];

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
       
      <ContentContainer>
      <Box mb={0} style={{  background: theme.palette.background.paper, 
        padding: 10, 
        paddingBottom: 30, 
        paddingTop: 60 }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: '#333',
            mb: 2,
            mt: 2,
            fontFamily: 'Arial, sans-serif',
            fontSize: 40,
          }}
        >
          BioMethod AI
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          sx={{
            color: '#555',
            mb: 0,
            fontFamily: 'Arial, sans-serif',
            fontSize: 24,
          }}
        >
          Data-driven workflows, modeling, and optimization for bioprocess experiments. 
          {/* Generate your models and optimize your parameters with data-driven design. */}
        </Typography>
      </Box>
        <Box sx={{paddingTop: 10, paddingBottom: 50}}>
        <Grid container spacing={4} justifyContent="center">
          {nodes.map((node) => (
            <Grid item xs={12} md={12} lg={6} xl={3} key={node.id}>
              <Box
                onClick={() => navigate(node.path)}
                sx={{
                  borderRadius: '20px',
                  backgroundColor: node.color,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 2,
                  height: '300px', // Set height to keep boxes uniform
                  textAlign: 'center',
                  color: '#333',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.3s ease, transform 0.3s ease',
                  '&:hover': {
                    backgroundColor: node.hoverColor,
                    transform: 'translateY(-5px)',
                  },
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '24px', mb: 1 }}>
                  {node.title}
                </Typography>
                {node.icon}
                <Typography variant="body2" sx={{ fontSize: '18px', color: '#555', mt: 1 }}>
                  {node.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        </Box>
      </ContentContainer>
    </>
  );
};

export default Home;
