import React from 'react';
import { Box } from '@mui/material';

interface ContentContainerProps {
  children: React.ReactNode;
}

const ContentContainer: React.FC<ContentContainerProps> = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        padding: '10px 24px',
        borderBottomLeftRadius: '8px', // Only Bottom left corner
        borderBottomRightRadius: '8px', // Only Bottom right corner
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        marginBottom: '24px',
      }}
    >
      {children}
    </Box>
  );
};

export default ContentContainer;
