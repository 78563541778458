import React, { createContext, useContext, useState } from 'react';

// Define context type
interface ExperimentContextType {
    factors: string[];
    setFactors: React.Dispatch<React.SetStateAction<string[]>>;
}

const ExperimentContext = createContext<ExperimentContextType | undefined>(undefined);

export const ExperimentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [factors, setFactors] = useState<string[]>([]);
    return (
        <ExperimentContext.Provider value={{ factors, setFactors }}>
            {children}
        </ExperimentContext.Provider>
    );
};

export const useExperiment = () => {
    const context = useContext(ExperimentContext);
    if (!context) {
        throw new Error('useExperiment must be used within an ExperimentProvider');
    }
    return context;
};
